import request from "@/core/services/axios";
export function getStatisticsList(params) {
  // 查询学业分析列表
  return request({
    url: `/report/stat/page`,
    method: "post",
    data: params,
  });
}
export function getunionlist(params) {
  // 联考统计列表
  return request({
    url: `/report/statoneexam/unionlist`,
    method: "post",
    data: params,
  });
}

export function deleteReport(params) {
  // 删除报告
  return request({
    url: `/report/stat/deletecustomstat`,
    method: "get",
    params: params,
  });
}
export function importstatstudentinclude(params) {
  // 导入不参统考生
  return request({
    url: `/report/stat/importstatstudentinclude?id=${params.id}`,
    method: "post",
    data: params.file,
  });
}
export function upDateStatusViews(params) {
  // 更改查看状态
  return request({
    url: `/report/stat/changestatusview`,
    method: "get",
    params: params,
  });
}
export function getsubjectmergelist(params) {
  // 查询合并科目列表
  return request({
    url: `/report/statsubject/getsubjectmergelist`,
    method: "get",
    params: params,
  });
}
export function removesubjectmerge(params) {
  // 删除合并科目
  return request({
    url: `/report/statsubject/removesubjectmerge`,
    method: "get",
    params: params,
  });
}
export function createsubjectmerge(params) {
  // 创建合并科目
  return request({
    url: `/report/statsubject/createsubjectmerge`,
    method: "post",
    data: params,
  });
}
export function getsubjectsplitlist(params) {
  // 拆分科目页列表
  return request({
    url: `/report/statsubject/getsubjectsplitlist`,
    method: "get",
    params: params,
  });
}
export function removesubjectsplit(params) {
  // 删除拆分科目
  return request({
    url: `/report/statsubject/removesubjectsplit`,
    method: "get",
    params: params,
  });
}
export function changestatsheetprintsetting(params) {
  // 更改批注信息设置
  return request({
    url: `/report/stat/changestatsheetprintsetting`,
    method: "post",
    data: params,
  });
}

export function getquestionlist(params) {
  // 获取被拆分科目试题列表
  return request({
    url: `/report/statsubject/getquestionlist`,
    method: "get",
    params: params,
  });
}
export function createsubjectsplit(params) {
  //创建拆分科目
  return request({
    url: `/report/statsubject/createsubjectsplit`,
    method: "post",
    data: params,
  });
}
export function generatestat(params) {
  // 生成统计
  return request({
    url: `/report/stat/generatestat`,
    method: "get",
    params: params,
  });
}
export function setexamdatarelease(params) {
  // 保存考试报告自动发布设置
  //dataRelease 报告自动发布设置 2-报告自动发布给教师 3-报告自动发布给学生 两个都不设置传空字符串或null 两个都设置则传 2,3
  return request({
    url: `/report/stat/setexamdatarelease`,
    method: "get",
    params: params,
  });
}
export function getstatprogress(params) {
  // 获取统计进度状态（轮询）
  return request({
    url: `/report/stat/getstatprogress`,
    method: "get",
    params: params,
  });
}

export function openstat(params) {
  // 开始统计
  return request({
    url: `/report/stat/openstat`,
    method: "get",
    params: params,
  });
}
export function getexistsubjectlist(params) {
  // 获取统计已有的科目列表
  return request({
    url: `/report/statsubject/getexistsubjectlist`,
    method: "get",
    params: params,
  });
}
export function getexamlistbyyear(params) {
  // 通过入学年份获取考试
  return request({
    url: `/report/stat/getexamlist`,
    method: "post",
    data: params,
  });
}
export function getexamsubjectlist(params) {
  // 获取考试的科目列表
  return request({
    url: `/report/stat/getexamsubjectlist`,
    method: "get",
    params: params,
  });
}
export function createcustomstat(params) {
  // 创建自定义统计
  return request({
    url: `/report/stat/createcustomstat`,
    method: "get",
    params: params,
  });
}
export function getstatmethodsetting(params) {
  // 获取统计方式设置
  return request({
    url: `/report/stat/getstatmethodsetting`,
    method: "get",
    params: params,
  });
}
export function setstatmethodsetting(params) {
  // 保存统计方式设置
  return request({
    url: `/report/stat/setstatmethodsetting`,
    method: "post",
    data: params,
  });
}
export function getstatsetting(params) {
  // 获取统计参数设置
  return request({
    url: `/report/stat/getstatparametersetting`,
    method: "get",
    params: params,
  });
}
export function setstatsetting(params) {
  // 保存统计参数设置
  return request({
    url: `/report/stat/setstatparametersetting`,
    method: "post",
    data: params,
  });
}
export function getstatcomparelist(params) {
  // 获取考试列表
  return request({
    url: `/report/stat/getstatcomparelist`,
    method: "post",
    data: params,
  });
}
export function studentincludepage(params) {
  // 获取学生不参与统计分页接口
  return request({
    url: `/report/stat/studentincludepage`,
    method: "post",
    data: params,
  });
}
export function setstatstudentinclude(params) {
  // 保存学生不参与统计设置
  return request({
    url: `/report/stat/setstatstudentinclude`,
    method: "get",
    params: params,
  });
}
export function quicksetstatstudentinclude(params) {
  // 快捷设置不参与统计设置
  return request({
    url: `/report/stat/quicksetstatstudentinclude`,
    method: "post",
    data: params,
  });
}
export function clearstatstudentinclude(params) {
  // 清空不参与统计设置
  return request({
    url: `/report/stat/clearstatstudentinclude`,
    method: "get",
    params: params,
  });
}
export function getcanmergesubjectlist(params) {
  // 合并科目-创建合并科目弹窗
  return request({
    url: `/report/statsubject/getcanmergesubjectlist`,
    method: "get",
    params: params,
  });
}
export function getcanbemergedsubjectlist(params) {
  // 获取未被合并的科目列表
  return request({
    url: `/report/statsubject/getcanbemergedsubjectlist`,
    method: "get",
    params: params,
  });
}
export function getstatsettinglist(params) {
  // 获取该学校该年级所有的统计参数设置
  return request({
    url: `/report/stat/getstatsettinglist`,
    method: "post",
    data: params,
  });
}
export function changestatsetting(params) {
  // 修改统计的统计参数设置
  return request({
    url: `/report/stat/changestatsetting`,
    method: "get",
    params: params,
  });
}
export function importstatstudentsubjectapscore(params) {
  //通过模板导入赋分结果接口;
  return request({
    url: `/report/stat/importstatstudentsubjectapscore`,
    method: "get",
    params: params,
  });
}
export function downloadstatstudentsubjectapscoretemplate(params) {
  //下载导入赋分结果模板
  return request({
    url: `/report/stat/downloadstatstudentsubjectapscoretemplate`,
    method: "get",
    params: params,
  });
}
export function setapsource(params) {
  //清除赋分结果 设置赋分方式
  return request({
    url: `/report/stat/setapsource`,
    method: "get",
    params: params,
  });
}
export function calculateapscorebyscoreori(params) {
  //使用原始分直接生成赋分
  return request({
    url: `/report/stat/calculateapscorebyscoreori`,
    method: "get",
    params: params,
  });
}
