<template>
  <div v-loading="" class="GradeRank">
    <detailsHeader hidden-back />
    <div class="filter-index">
      <div class="left">
        <div class="category">
          <div class="category-title">教学</div>
          <el-checkbox v-model="visibleForm.classNum" disabled>
            <el-tooltip content="必选项" placement="bottom" effect="dark">
              <span>{{
                $route.query.subjectId == 0 ? "行政班级" : "教学班级"
              }}</span>
            </el-tooltip>
          </el-checkbox>
          <el-checkbox v-model="visibleForm.teacherName">
            <span>{{
              $route.query.subjectId == 0 ? "班主任" : "任课老师"
            }}</span>
          </el-checkbox>
        </div>
        <div class="category">
          <div class="category-title">人数</div>
          <el-checkbox v-model="visibleForm.total">总人数</el-checkbox>
          <el-checkbox v-model="visibleForm.countRight">满分人数</el-checkbox>
          <el-checkbox v-model="visibleForm.segmentsTotal" disabled>
            <el-tooltip content="必选项" placement="bottom" effect="dark">
              <span>分段人数</span>
            </el-tooltip>
          </el-checkbox>
          <el-checkbox v-model="visibleForm.accumulativeTotal"
            >累计人数</el-checkbox
          >
        </div>
      </div>
      <stat-params />
    </div>
    <div class="table-content">
      <div class="operate">
        <div class="title">分数段对比 - {{ $route.query.subjectName }}</div>
        <div class="operate-box">
          <el-input
            v-model="search.input"
            style="width: 180px; margin-right: 18px"
            suffix-icon="el-icon-search"
            placeholder="搜索"
            @input="inputChange"
          ></el-input>
          <el-dropdown @command="dropdownChange">
            <el-button type="primary">
              导出为Excel<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="current">当前报表</el-dropdown-item>
              <el-dropdown-item command="detail">详细报表</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <resEleccomb-list
        v-if="$route.query.subjectId === '0'"
        :res-eleccomb-list="resEleccombList"
        :res-eleccomb-id="resEleccombId"
        @change="handleClick"
      />
      <el-table
        ref="elTable"
        :data="tableData"
        border
        style="width: 100%; margin-bottom: 18px"
      >
        <el-table-column
          v-if="visibleForm.classNum"
          prop="classNum"
          :label="$route.query.subjectId == 0 ? '行政班级' : '教学班级'"
          :filters="filterData"
          :filter-method="filterHandler"
          column-key="classNum"
          fixed
          align="center"
          width="110"
        >
          <template #header>
            {{ $route.query.subjectId == 0 ? "行政班级" : "教学班级" }}
            <img src="@/assets/newExam/sizer.svg" alt=""
          /></template>
        </el-table-column>
        <el-table-column
          v-if="visibleForm.total"
          prop="total"
          fixed
          label="总人数"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="visibleForm.teacherName"
          prop="teacherName"
          fixed
          :label="$route.query.subjectId === '0' ? '班主任' : '任课老师'"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          v-if="visibleForm.countRight"
          prop="countRight"
          fixed
          label="满分人数"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column label="" width="100" align="center" fixed>
          <template>
            <div class="one">分段人数</div>
            <div v-if="visibleForm.accumulativeTotal" class="two">累计人数</div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in segments"
          :key="index"
          :label="item.segment"
          align="center"
          width="120"
        >
          <template slot-scope="{ row }">
            <div class="one">{{ row.segments[index].total }}</div>
            <div v-if="visibleForm.accumulativeTotal" class="two">
              {{ row.segments[index].accumulativeTotal }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="pagination">
        <el-pagination
          :current-page="search.current"
          :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
          :page-size="search.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div> -->
    </div>
    <el-dialog
      title="导出详细榜单"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="export-box">
        <div class="export-box-title">选择更多数据项</div>
      </div>
      <div class="option">
        <div class="option-title">教学</div>
        <div class="checks">
          <el-checkbox
            v-model="checked"
            :true-label="1"
            :false-label="0"
            disabled
          >
            {{ $route.query.subjectId == "0" ? "行政班级" : "教学班级" }}
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expTeacherName"
            :true-label="1"
            :false-label="0"
          >
            {{ $route.query.subjectId == "0" ? "班主任" : "任课老师" }}
          </el-checkbox>
        </div>
      </div>
      <div class="option">
        <div class="option-title">人数</div>
        <div class="checks">
          <el-checkbox
            v-model="exportForm.expTotal"
            :true-label="1"
            :false-label="0"
          >
            总人数
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expCountRight"
            :true-label="1"
            :false-label="0"
          >
            满分人数
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expSegmentTotal"
            :true-label="1"
            :false-label="0"
          >
            分段人数
          </el-checkbox>
          <el-checkbox
            v-model="exportForm.expAccumulativeTotal"
            :true-label="1"
            :false-label="0"
          >
            累计人数
          </el-checkbox>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="exportDetail">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import detailsHeader from "@/components/detailsHeader.vue";
import StatParams from "./components/StatParams.vue";
import { getObj } from "@/core/api/exam/exam";
import { getstateleccomboptionV2 } from "@/core/api/academic/common";
import {
  scoresegmentcompare,
  exportscoresegmentcompare,
} from "@/core/api/academic/onceExam";
import resEleccombList from "./components/resEleccombList.vue";
export default {
  components: {
    detailsHeader,
    StatParams,
    resEleccombList,
  },
  data() {
    return {
      checked: 1,
      value: true,
      examInformation: null,
      search: {
        current: 1,
        size: 20,
      },
      total: 0,
      resEleccombId: 1,
      filterData: [],
      tableData: [],
      visibleForm: {
        classNum: true,
        countRight: true,
        total: true,
        segmentsTotal: true,
        accumulativeTotal: true,
        teacherName: true,
      },
      dialogVisible: false,
      resEleccombList: [],
      tokenKey: Math.random(),
      segments: [],
      exportForm: {
        expTotal: 1,
        expCountRight: 1,
        expSegmentTotal: 1,
        expAccumulativeTotal: 1,
        expTeacherName: 1,
      },
    };
  },
  mounted() {
    this.getExamObj();
  },
  methods: {
    inputChange() {
      this.search.current = 1;
      this.getData();
    },
    async exportDetail() {
      const { statId, subjectId } = this.$route.query;
      const res = await exportscoresegmentcompare({
        statId: Number(statId),
        subjectId: subjectId,
        resEleccombId: this.resEleccombId,
        ...this.exportForm,
        ...this.search,
      });
      this.dialogVisible = false;
      if (res.msg) {
        this.$message({
          type: "warning",
          message: res.msg,
          showClose: true,
        });
      } else {
        const { href } = this.$router.resolve({
          path: "/downFile",
          query: {
            url: res.data.data.url,
            name: res.data.data.name,
            type: 1,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    visibleCate(subjectId) {
      let id = subjectId;
      if (typeof id === "number") id = id.toString();
      let hasPhysics = this.examInformation.subjectIds.indexOf(subjectId) != -1;
      let current = ["0", "1", "2", "3", "4", "8"].includes(
        this.$route.query.subjectId
      );
      let isMustChoose =
        ["4", "8"].includes(subjectId) && id == this.$route.query.subjectId;
      if (["0", "1", "2", "3"].includes(this.$route.query.subjectId)) {
        return 1;
      } else {
        if (!isMustChoose) {
          return 0;
        } else if (hasPhysics && current) {
          return 1;
        } else {
          return 0;
        }
      }
    },
    async getGroup() {
      const { statId, subjectId } = this.$route.query;
      const res = await getstateleccomboptionV2({
        id: statId,
        subjectId: subjectId == "0" ? null : subjectId,
        eleccateId: 0,
        hasCate: 1,
      });
      this.resEleccombList = res.data.data;
      if (res.data.data.length) {
        let length = res.data.data.length;
        this.resEleccombId = length >= 2 ? 0 : res.data.data[0].id;
        this.getData();
      }
    },
    async getData() {
      this.loading = true;
      try {
        const res = await scoresegmentcompare({
          ...this.search,
          resEleccombId: this.resEleccombId,
          statId: this.$route.query.statId,
          subjectId: this.$route.query.subjectId,
        });
        this.filterData = res.data.data.map((item) => ({
          text: item.classNum,
          value: item.classNum,
        }));
        this.tableData = res.data.data.filter((item) => item.total);
        // this.total = res.data.data.total;
        if (res.data.data.length) {
          this.segments = res.data.data[0].segments;
          this.tokenKey = Math.random();
          this.$nextTick(() => {
            this.$refs.elTable.doLayout();
          });
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getExamObj() {
      const res = await getObj(this.$route.query.examId);
      this.examInformation = res.data.data;
      this.getGroup();
    },

    async dropdownChange(command) {
      if (command === "detail") {
        this.dialogVisible = true;
      } else {
        let exportParams = {};
        for (let key in this.visibleForm) {
          let newKey = "exp" + key.slice(0, 1).toUpperCase() + key.slice(1);
          exportParams[newKey] = Number(this.visibleForm[key]);
        }
        const { statId, subjectId } = this.$route.query;
        const res = await exportscoresegmentcompare({
          statId: Number(statId),
          subjectId: subjectId,
          resEleccombId: this.resEleccombId,
          ...this.search,
          ...exportParams,
        });
        if (res.msg) {
          this.$message({
            type: "warning",
            message: res.msg,
            showClose: true,
          });
        } else {
          const { href } = this.$router.resolve({
            path: "/downFile",
            query: {
              url: res.data.data.url,
              name: res.data.data.name,
              type: 1,
            },
          });
          let timer = setTimeout(() => {
            window.open(href, "_blank");
            clearTimeout(timer);
          }, 0);
        }
      }
    },
    handleClick(item) {
      this.resEleccombId = item.id;
      this.getData();
    },
    filterHandler(value, row, column) {
      // 表格筛选
      const property = column["property"];
      return row[property] === value;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./filter.module.scss";

.filter-index {
  box-sizing: border-box;
  padding: 12px 18px;
  background-color: #fff;
  border-radius: 4px;
  margin: 18px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: 18px;
    grid-row-gap: 18px;

    .category {
      height: 62px;
      background: #fbfcfd;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 8px 18px;
      grid-gap: 18px;

      .category-title {
        color: #0a1119;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 6px;
      }

      .el-checkbox {
        margin-bottom: 0;
        margin-right: 0;
      }

      .el-checkbox + .el-checkbox {
        margin-left: 18px;
      }
    }
  }
}

.GradeRank {
  box-sizing: border-box;
  padding-top: 18px;
  .table-content {
    box-sizing: border-box;
    padding: 18px;
    border-radius: 4px;
    background-color: #fff;
    .operate {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 18px;
      .title {
        color: #2474ed;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .subjects {
      border: 1px solid #ebecee;
      box-sizing: border-box;
      padding: 12px 18px;
      border-bottom: none;
      grid-column-gap: 18px;
      grid-row-gap: 18px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 4px 4px 0px 0px;
      .subject-item {
        height: 32px;
        border-radius: 4px;
        border: 1px solid #d5d6db;
        line-height: 32px;
        text-align: center;
        box-sizing: border-box;
        padding: 0 18px;
        cursor: pointer;
      }
      .active {
        border-color: #2474ed;
        color: #2474ed;
      }
    }
    .pagination {
      display: flex;
      justify-content: flex-end;
    }
  }
}
.export-box {
  &-title {
    font-size: 14px;
    color: #0a1119;
    margin: 18px 0;
  }
}
.option {
  margin-bottom: 18px;
  border: 1px solid #ebecee;
  &-title {
    background: #f5f7fa;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #ebecee;
    height: 44px;
    box-sizing: border-box;
    line-height: 44px;
    padding: 0 18px;
  }
  .checks {
    height: 44px;
    line-height: 44px;
    box-sizing: border-box;
    padding: 0 18px;
    .el-checkbox {
      margin-bottom: 0;
      margin-right: 0;
    }
    .el-checkbox + .el-checkbox {
      margin-left: 18px;
    }
  }
}

::v-deep .el-dialog__footer {
  text-align: center !important;
}
.one {
  text-align: center;
}
.two {
  margin-top: 6.5px;
  border-top: 1px solid #ebeef5;
  text-align: center;
  padding-top: 6.5px;
}
::v-deep .el-icon-arrow-down:before {
  content: "";
  font-size: 18px;
}
</style>
