<template>
  <div>
    <div class="stat-params">
      <div class="title">以赋分统计</div>
      <div class="btns" @click="openDrawer">
        <img src="@/assets/imgs/academic/fullscreen.png" alt="" />
        <el-button type="text"> 显示统计参数</el-button>
      </div>
    </div>
    <el-drawer
      title="查看统计信息"
      :visible.sync="drawer"
      :direction="'rtl'"
      :before-close="handleClose"
    >
      <div class="params">
        <div class="params-title">
          <div class="text">统计类型</div>
        </div>
        <div class="params-content">
          <div
            v-if="stateSetting.stat && stateSetting.stat.scoreType === 0"
            class="text"
          >
            原始分统计
          </div>
          <div
            v-if="stateSetting.stat && stateSetting.stat.scoreType === 1"
            class="text"
          >
            以赋分统计
          </div>
        </div>
        <div class="params-title">
          <div class="text">统计方式</div>
        </div>
        <div class="params-content">
          <div class="params-content-title">* 基础统计设置</div>
          <div class="params-content-tip">
            按参统条件统计：{{
              stateSetting.stat.apExcludeZero === 1 ? "零分除外；" : ""
            }}缺考除外；
          </div>
          <div class="params-content-text">
            班级数：{{ stateSetting.classCount }}个
          </div>
          <div class="params-content-text">
            参统数：{{ stateSetting.statTotal || 0 }}人
          </div>
          <div class="params-content-text">
            总人数：{{ stateSetting.total || 0 }}人
          </div>
          <div class="params-content-title">* 高级统计设置</div>
          <div class="params-content-text">
            已启用；共 1 名考生指定成绩除外统计
          </div>
        </div>
        <div class="params-title">
          <div class="text">统计参数</div>
        </div>
        <div class="params-content">
          <div class="params-content-title">* 前N名区间</div>
          <div
            v-for="(item, index) in stateSetting.topSections"
            :key="'topSections' + index"
            class="row"
          >
            <div class="label">{{ getSubjectName(item.subjectId) }}</div>
            <div class="value">{{ item.value }}</div>
          </div>
          <div class="params-content-title">* 分数段区间</div>
          <div
            v-for="(item, index) in stateSetting.statSegmentSettings"
            :key="'statSegmentSettings' + index"
            class="row"
          >
            <div class="label">
              <span>{{ getSubjectName(item.subjectId) }}</span
              ><span>{{ getSubScore(item.subjectId) || 0 }}</span>
            </div>
            <div class="value">
              上限 {{ item.maximum }}～{{ item.minimum }} 下限
            </div>
            <div class="value">每{{ item.gap }}分/段</div>
          </div>
          <div class="params-content-title">* 指标比率</div>
          <div class="row">
            <div class="label">基础指标</div>
            <div class="label">
              <span v-if="stateSetting.ratioType === 1"> 按分数比例 </span>
              <span v-if="stateSetting.ratioType === 2"> 按人数比例 </span>
            </div>
            <div class="label">总人数：{{ stateSetting.statTotal }}</div>
          </div>
          <div
            v-for="(item, index) in stateSetting.statRatioSettings"
            :key="'statRatioSettings' + index"
            class="row"
          >
            <div class="label">{{ item.name }}</div>
            <div class="value">
              <span v-if="stateSetting.ratioType === 1">分数</span>
              <span v-if="stateSetting.ratioType === 2">人数</span>
            </div>
            <div class="value">
              <span v-if="item.type === 1">前</span>
              <span v-if="item.type === 2">后</span>
              {{ item.value }}%
            </div>
          </div>
          <div class="params-content-title">* 全科统计参与科目</div>
          <div class="row">
            <div
              v-for="(item, index) in stateSetting.statSubjects"
              :key="'statSubjects' + index"
              class="label"
            >
              {{ getSubjectName(item.subjectId) }}
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {
  getstatmethodsetting,
  getstatsetting,
} from "@/core/api/academic/statistics";
import { filterSubject } from "@/core/util/util";

export default {
  data() {
    return {
      drawer: false,
      stateSetting: {
        stat: {},
      },
      subjectList: filterSubject(),
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    setParams() {
      const { examName, statId, ruleId } = this.$route.query;
      this.$router.push({
        path: "/academic/statistics/setting/settingWay",
        query: {
          name: examName,
          id: statId,
          ruleId: ruleId,
          examId: this.$parent.examInformation.examId,
          examType: this.$parent.examInformation.examType,
          level: this.$parent.examInformation.level,
          type: this.$parent.examInformation.type,
        },
      });
    },
    getSubjectName(id) {
      if (id === 0) {
        return "总分";
      } else {
        const subject = this.subjectList.find((item) => item.value === id);
        return subject.label;
      }
    },
    getSubScore(id) {
      if (id == "0") {
        return this.stateSetting.stat.score;
      } else {
        let subject = this.stateSetting.statSubjects.find(
          (item) => item.subjectId == id
        );
        if (subject) {
          return subject.score;
        } else {
          return 0;
        }
      }
    },
    async getData() {
      const res = await getstatsetting({ id: this.$route.query.statId });
      const methodSetting = await getstatmethodsetting({
        id: this.$route.query.statId,
      });
      this.stateSetting = {
        ...res.data.data,
        ...methodSetting.data.data,
        topSections: res.data.data.topSections.filter(
          (item) => item.subjectId != 0
        ),
      };
    },
    openDrawer() {
      this.drawer = true;
    },
    handleClose() {
      this.drawer = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.stat-params {
  width: 138px;
  box-sizing: border-box;
  padding: 8px 18px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #ededef;
  .title {
    margin: 0 auto;
    text-align: center;
    width: 80px;
    box-sizing: border-box;
    padding-bottom: 8px;
    border-bottom: 1px solid #ededef;
    margin-bottom: 8px;
  }
  .btns {
    display: flex;
    align-items: center;

    img {
      margin-right: 4px;
    }
  }
}
.params {
  box-sizing: border-box;
  padding: 0 24px;
  .params-title {
    height: 36px;
    background: #f3f8ff;
    border-radius: 4px;
    line-height: 36px;
    box-sizing: border-box;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text {
      color: #0a1119;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .params-content {
    margin: 18px 0;
    box-sizing: border-box;
    padding: 0 8px;
    &-title {
      color: #0a1119;
      font-weight: 600;
      margin-bottom: 12px;
      font-size: 14px;
    }
    &-tip {
      color: #3e4551;
      font-size: 14px;
      margin-bottom: 12px;
      font-weight: normal;
    }
    &-text {
      color: #3e4551;
      font-size: 14px;
      margin-bottom: 12px;
      font-weight: normal;
    }
    .row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 12px;
      color: #3e4551;
      font-size: 14px;
      .label {
        color: #0a1119;
        margin-right: 18px;
        span + span {
          margin-left: 8px;
        }
      }

      .value + .value {
        margin-left: 18px;
      }
    }
  }
}
::v-deep .el-drawer__header {
  border-bottom: 1px solid #ededef;
  box-sizing: border-box;
  padding: 18px !important;
  padding-bottom: 18px !important;
  color: #0a1119 !important;
  font-size: 16px;
  font-weight: 600;
}
</style>
